$(function(){
// 初始化回到顶部和客服
    if (window['SlideNav']) {
        var sn = new SlideNav();
        sn.addItem('goTop');
        SlideNav.defaultsInit(sn);
        sn.show();
    }
    /*输入字数限制*/
    var wordRestriction = function () {
        var questionsTmp = $(".J-textarea").val().replace(/[\r\n]/g, "*").trim().length;
        if(questionsTmp <= 4000){
            $('.J-text-right span').html(questionsTmp);
        }else {
            $('.J-text-right span').html(4000);
        }

    };
    wordRestriction();

    $(".J-textarea").keyup(function(){
        wordRestriction()
    });

    $.validator.addMethod("maxlength",function(val,ele,param){
        return val.length <= param;
    });

    /*表单验证*/
    $.validator.define("contactUsForm", {
        yourName: {
            required: {
                message: ["Please enter your name.", "请输入你的名字。"]
            }
        },
        phoneNumber: {
            enOnly: {
                message: ["Please enter a valid phone number.", "不允许输入非英文字符。"]
            }
        },
        email: {
            required: {
                message: ["Please enter a valid email address.", "请输入邮箱。"]
            },
            email: {
                message: ["Please enter email address in a valid format.", "不允许输入非英文字符或非法格式。"]
            }
        },
        questions: {
            required: {
                message: ["Please specify your questions.", "请输入你的问题。"]
            },
            maxlength: {
                param: 4000,
                message: "Questions may only be up to 4,000 characters."
            }
        },
        validateCode: {
            required: ['Please enter the characters you see in the picture.']/*'The verification code is invalid, Please try again.'*/
        }
    });

    $(".J-consultation-form").validate("contactUsForm", {
        errorWrap: true,
        ignore: ".J-ignore",
        submitHandler: function (form) {
            var $submit = $(form).find('.J-add-submit');
            $submit.attr('disabled', true);
            $.ajax({
                url: "/help/sendUs",
                type: "POST",
                data: $('.J-consultation-form').serialize(),
                dataType: "json",
                success: function (reqdata) {
                    if (reqdata.code === "10001") {
                        artDialog.alert('Thanks for contacting CROV, we will reply you ASAP.', {
                            text: "Confirm",
                            fn: function () {
                                window.location.reload();
                            }
                        });
                    } else if (reqdata.code === "10002") {
                        $('.J-error-tips').show().find(".J-alert-text").text(reqdata.data);
                    }
                },
                error: function () {
                    alert(SILK.ASYNC_ERROR);
                },
                complete: function(){
                    $submit.removeAttrs('disabled');
                }
            });
        }
    });

});